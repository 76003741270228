import * as React from "react";
import Typewriter from "typewriter-effect";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Metadata from "../components/Metadata";

const IndexPage = () => {
  return (
    <div className="container">
      <Metadata title="Home" description="Home" />

      <Header />

      <article>
        <div className="typewriter">
          <Typewriter
            options={{
              loop: true,
              delay: 100,
              wrapperClassName: "my-typewriter-class",
              cursorClassName: "Typewriter__cursor my-typewriter-cursor-add",
            }}
            onInit={(typewriter) => {
              typewriter.pauseFor(2000);

              typewriter.typeString("Hello !").pauseFor(4500).deleteAll();

              typewriter.typeString("Servus !").pauseFor(4500).deleteAll();

              typewriter.typeString("नमस्ते !").pauseFor(4500).deleteAll();

              typewriter.typeString("നമസ്‌കാരം !").pauseFor(4500).deleteAll();

              typewriter
                .typeString("வணக்கம் !")
                .pauseFor(4500)
                .deleteAll()

                .start();
            }}
          />
        </div>

        <p>
          I'm Vaishak Kaippanchery from <strike>Madras</strike>{" "}
          <a href="https://en.wikipedia.org/wiki/Chennai">Chennai</a>. Currently
          staying in
          <a href="https://en.wikipedia.org/wiki/Bayreuth"> Bayreuth</a>.
        </p>

        <p>
          I am a Software Engineer working as a Front end Developer for
          <a href="http://liqid.de/"> Liqid Investments</a>, Berlin. I
          previously worked for <a href="https://www.zoho.com/">Zoho</a> where I
          was an integral part of the development of a design collaboration
          application called <a href="https://nila.cloud/">Nila.</a> I prefer
          front end. I can do back end.
        </p>

        <p>
          I consider myself a Minimalist, and a digital privacy advocate. Always
          curious and to some extend perplexed by the rapid evolution of trends
          in technology.
        </p>

        <p>
          I have always been fascinated by tools of thought and am currently in
          the neverending pursuit of finding that one <b>perfect</b> tool to add
          it to my stack.
        </p>

        <p>
          Here's what I'm doing right <a href="/now/">now</a>.
        </p>
      </article>

      <Footer />
    </div>
  );
};

export default IndexPage;
